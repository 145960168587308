.App {
  position: relative;
  min-height: 100vh; /* ビューポートの最小高さを保証 */
  height: 100%; /* コンテンツの高さに合わせて伸ばす */
  text-align: center;
  background-image: url(../images/rainbow_back2_2048.png);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Kiwi';
  src: local('Kiwi'),url(../fonts/KiwiMaru-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Kosugi';
  src: local('Kosugi'),url(../fonts/KosugiMaru-Regular.ttf) format('truetype');
}

p {
  font-family: Kosugi;
}

.SimpleTabContentMargin {
  margin-top: 10px;
  background-color: white;
  padding: 8px;
  border-radius: 10px;
}
.SimpleTabContent {
  padding: 2px;
  box-shadow: none;
  border-style: dashed;
  border-color: lightpink;
  border-radius: 10px;
  margin: 4px;

}

#TabBar {
  background: radial-gradient(#ffffff, rgba(0,0,0,0),rgba(0,0,0,0) 80%);
}

.navMargin {
  margin-top: 40px;
  background-color: white;
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 40px;
}

nav {
  margin: 4px;
  border-style: dashed;
  border-color: lightpink;
  border-radius: 10px; 

}

nav a {
  font-family: 'Roboto', sans-serif; /* Robotoフォントを使用 */
  font-weight: bold; /* ボールド体に設定 */
  color: black; /* テキストの色を黒に設定 */
  text-decoration: none; /* 下線を削除 */
}

nav a.active {
  background-color: rgb(222, 222, 222); /* アクティブなリンクの背景色をグレーに設定 */
}

nav a:not(.active):hover {
  background-color: lightgrey; /* ホバー時の背景色を薄いグレーに設定 */
}

