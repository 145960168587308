#CharactorRightArea {
  box-shadow: none;
  border: none;
  background-color: rgba(0,0,0,0);
}
.IrisiaStands {
  padding: 2px;
  box-shadow: none;
  border-style: dashed;
  border-color: lightpink;
  border-radius: 10px;
}

.IrisiaEmotions {
  padding: 2px;
  box-shadow: none;
  border-style: dashed;
  border-color: yellow;
  border-radius: 10px;
}

.IrisiaMessage {
  box-shadow: none;
  border-style: dashed;
  border-color: skyblue;
  border-radius: 10px;
}

#IrisiaStandsImg {
  height: 750px;
}

#IrisiaEmotionsImg {
  width: 75%
}

.CharactorContentMargin {
  background-color: white;
  padding: 8px;
  border-radius: 20px;
  box-shadow: none;
  border: none
}
