.Contributor {
  display: flex;
  justify-content: center; /* Centers children horizontally */
  align-items: center; /* Aligns items vertically in the center */
  padding: 2px;
  box-shadow: none;
  border-style: dashed;
  border-radius: 10px;
  margin: 4px;
  width: 100%; /* Sets the width to take up the full width of the parent */
  flex-grow: 1; /* Allows the element to grow to fill the space */
}

.ContributorMargin {
  display: flex;
  margin-top: 10px;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  width: 100%; /* Ensures the container takes full width of its parent */
}

.ContributorLeft, .ContributorRight {
  flex: 1; /* Each takes up half the space */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.ContributorLeft img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.ContributorInfo {
  text-align: left; /* Align text to the left */
  line-height: 0.1; /* Set line height */
  font-size: smaller;
}

.border-skyblue {
  border-color: skyblue;
}

.border-yellow {
  border-color: yellow;
}

.border-lightpink {
  border-color: lightpink;
}